import { defineStore } from 'pinia'
import {reactive} from "vue";

export const configStore = defineStore('config', {
    state: () => ({
        projectURL: 'https://vue.teambodycoach.app/',
        socketURL: 'https://vue.teambodycoach.app/live',
        darkMode: 0,
        volume: 0,
        vibrate: 0,
        chatModal: false,
        sidebar: false,
        now: Date.now(),
        cordova: process.env.CORDOVA_PLATFORM ? process.env.CORDOVA_PLATFORM.toUpperCase() : false,
        onlineStatus: 15*60*1000,
    }),
    getters: {
        timer() {
            return this.now
        },
        calculateTimeString () {
            return (time) => {
                let remainTime = Math.floor((Date.now() - time) / 1000)
                let timeString = remainTime + 's ago'
                if (remainTime <= 10) timeString = 'Now'
                if (remainTime > 59) {
                    remainTime = Math.floor(remainTime / 60)
                    timeString = remainTime + ' min. ago'
                    if (remainTime > 59) {
                        remainTime = Math.floor(remainTime / 60)
                        timeString = remainTime + ' h ago'
                        if (remainTime > 23) {
                            remainTime = Math.floor(remainTime / 24)
                            timeString = remainTime + ' days ago'
                            if (remainTime === 1)timeString = remainTime + ' day ago'
                            if (remainTime > 6) {
                                const date = new Date(time)
                                timeString = 'on ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                            }
                        }
                    }
                    return timeString
                }}
        },
    }
})