<template>
  <main class="content-1400 page-content">
    <div class="grid-12">
      <div class="col-sp-12 col-tb-8 row-sp-12 pad-8">
        <h1>Übersicht</h1>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-8 t-right pad-top-16">
        <div class="field simple">
          <input id="search" placeholder="Suche..." name="search" type="search" v-model="searchString">
        </div>
      </div>
    </div>
    <div class="grid-12 pad-8">
      <application v-for="application in applicationsFiltered" :key="application.id" :application="application" />
    </div>
    <div class="t-center pad-16" v-if="!applications.length">
      <p class="color-grey">
        Keine Bewerbungen vorhanden
      </p>
    </div>
    <div class="pad-8" />
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import application from "@/components/bewerbungen/application";
import {aSyncData} from "@/modules/main";


export default {
  name: "overview",
  title: "Bewerbungen Overview",
  components: {
    application,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      applications: [],
      searchString: ''
    }
  },
  computed: {
    applicationsFiltered(){
      return this.applications.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {
            if(value.includes(this.searchString)) result = true
          }
        }
        return result
      })
    }
  },
  mounted() {
    this.config.loading = false
    this.getApplications()
  },
  methods: {
    getApplications(){
      aSyncData('bewerbungen/getApplications')
          .then(r => {
            if(r.status === 250) this.applications = r.data
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>
#search{
  display: block;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}
</style>