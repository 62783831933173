<template>
  <main class="content-1400 page-content apply">
    <div class="pad-16">
      <div class="header grid-12">
        <div class="col-sp-12 col-sd-8 row-sp-12 search">
          <input type="text" v-model="searchString" placeholder="Beruf, Jobtitel oder Stichwörter">
        </div>
        <div class="col-sp-0 col-sd-4 row-sp-12" />
        <div class="col-sp-12 col-sd-6 row-sp-12">
          <div class="flex filter-wrapper">
            <div class="filter" @click="modals.jobs = true">
              Beruf
            </div>
            <div class="filter" @click="modals.locations = true">
              Ort
            </div>
            <div class="filter" @click="modals.workTypes = true">
              Anstellungsart
            </div>
            <div class="col-sp-12 col-sd-3 row-sp-12 resetfilterDesktop" style="align-self: center">
              <span style="display: block; padding: 10px; text-decoration: underline; font-size: 0.8em;" @click="resetFitler()">Alle Filter zurücksetzen</span>
            </div>
          </div>
        </div>
        <div class="col-sp-12 row-sp-12 resetfilterMobil" style="align-self: center">
          <span style="display: block; padding: 10px; text-decoration: underline; font-size: 0.8em;" @click="resetFitler()">Alle Filter zurücksetzen</span>
        </div>
      </div>
      <div class="grid-12 jobsPreview">
        <div class="col-sp-12 col-sd-4 row-sp-12 jobsOverview" :class="this.job == '' ? 'show' : 'hide'">
          <loader v-if="loading" />
          <div v-else>
            <div v-for="jobItem in jobsFiltered" :key="jobItem.id" class="jobRight grid-5" @click="job = jobItem">
              <!--              <div class="col-sp-2 row-sp-12">-->
              <!--                <img :src="config.projectURL + 'assets/images/jobs/' + jobItem.titleimage + '_thumb.jpg'">-->
              <!--              </div>-->
              <div class="col-sp-5 row-sp-12">
                <div class="flex jobsPreviewTxt">
                  <div class="title">
                    <p>{{ jobItem.title }} (m/w/d)</p>
                  </div>
                  <div class="location">
                    <p>{{ jobItem.locationName }}</p>
                  </div>
                  <div class="description flex">
                    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" fill="#51a7dd" class="fill-000000" /><path d="M0 0h48v48H0z" fill="none" /></svg>
                    {{ jobItem.locationCity }}
                  </div>
                  <div class="workType">
                    <p>{{ jobItem.worktype }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!jobs.length">
              Aktuell keine offenen Stellen
            </div>
            <div v-else-if="!jobsFiltered.length">
              Zu den Filtern wurden leider keine offenen Stellen gefunden
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-sd-8 row-sp-12">
          <div v-if="job != ''" class="pad-8 display-Mobile">
            <button @click="job = ''" class="button fluid primary">
              Zurück
            </button>
          </div>
          <transition name="fade">
            <JobDetailsView :job="job" :key="job.id" />
          </transition>
        </div>
      </div>
      <Vue-Modal :show="modals.jobs" @close="modals.jobs = false" @approve="modals.jobs = false" approve="Okay">
        <div class="filter" v-for="item in jobSelection" :key="item" :class="{unselected: !selectedJobTypes.includes(item)}" @click="filter(selectedJobTypes, item)">
          {{ item }}
        </div>
      </Vue-Modal>
      <Vue-Modal :show="modals.locations" @close="modals.locations = false" @approve="modals.locations = false" approve="Okay">
        <div class="filter" v-for="item in locationSelection" :key="item" :class="{unselected: !selectedLocations.includes(item)}" @click="filter(selectedLocations, item)">
          {{ item }}
        </div>
      </Vue-Modal>
      <Vue-Modal :show="modals.workTypes" @close="modals.workTypes = false" @approve="modals.workTypes = false" approve="Okay">
        <div class="filter" v-for="item in workTypeSelection" :key="item" :class="{unselected: !selectedWorkTypes.includes(item)}" @click="filter(selectedWorkTypes, item)">
          {{ item }}
        </div>
      </Vue-Modal>
    </div>
  </main>
</template>

<script>
import Loader from "@/components/basic/loader";
import {aSyncData} from "@/modules/main";
import JobDetailsView from "@/components/apply/JobDetailsView";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "ApplyView",
  components: {JobDetailsView, Loader},
  title: 'Jetzt Bewerben!',
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      loading: true,
      modals: {
        jobs: false,
        locations: false,
        workTypes: false
      },
      searchString: '',
      selectedWorkTypes: [],
      selectedLocations: [],
      selectedJobTypes: [],
      jobs: [],
      job: {}
    }
  },
  computed: {
    jobsFiltered(){
      return this.jobs.filter(j => {
        if(!(j.title?.toLowerCase().includes(this.searchString.toLowerCase()))
            && !(j.description?.toLowerCase().includes(this.searchString.toLowerCase()))
            && !(j.locationName?.toLowerCase().includes(this.searchString.toLowerCase()))
            && !(j.locationCity?.toLowerCase().includes(this.searchString.toLowerCase()))
            && !(j.worktype?.toLowerCase().includes(this.searchString.toLowerCase()))) return false;

        if(this.selectedJobTypes.length){
          if(!this.selectedJobTypes.includes(j.title)) return false
        }
        if(this.selectedLocations.length){
          if(!this.selectedLocations.includes(j.locationCity)) return false
        }
        if(this.selectedWorkTypes.length){
          if(!this.selectedWorkTypes.includes(j.worktype)) return false
        }

        return true
      })
    },
    jobSelection(){
      return this.jobs.map(j => j.title).filter((value, index, array) => array.indexOf(value) === index)
    },
    locationSelection(){
      return this.jobs.map(j => j.locationCity).filter((value, index, array) => array.indexOf(value) === index)
    },
    workTypeSelection(){
      return this.jobs.map(j => j.worktype).filter((value, index, array) => array.indexOf(value) === index)
    }
  },
  watch: {
    jobsFiltered(){
      if(!this.jobsFiltered.some(j => j.id === this.job.id)) this.job = ''
    }
  },
  mounted() {
    this.loadJobs()
  },
  methods: {
    loadJobs(){
      aSyncData('apply/getAll')
          .then(r => {
            if(r.status === 250){
              this.jobs = r.data.jobs
            }
          })
          .catch(e => console.log(e))
          .finally(() => {this.loading = false})
    },
    resetFitler(){
      this.searchString = ''
      this.selectedJobTypes = []
      this.selectedLocations = []
      this.selectedWorkTypes = []
    },
    filter(array, item){
      console.log(array)
      const index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);
      }else{
        array.push(item)
      }
      console.log(array)
    }
  }
}
</script>

<style scoped>
.jobRight{
  border: 2px solid #F6F2F0;
  border-radius: 10px;
  margin: 10px;
  padding: 14px 10px;
  column-gap: 4px;
}

.jobRight img{
  margin: 5px;
  border-radius: 10px;
  width: calc(100% - 10px);
}

.filter{
  border-radius: 20px;
  background-color: var(--theme-pflegediakonie-secondary);
  padding: 10px 20px;
  text-align: center;
  font-size: 0.9em;
}

.unselected{
  background-color: lightgray !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
  position: absolute;
}
.fade-enter-from,
.fade-leave-to{
  opacity: 0;
  transform: translateX(250px);
}

.flex {
  display: flex;
}

.apply .search input{
  padding: 8px;
  border: none;
  border-bottom: 2px solid rgba(0,0,0,0.2);
  width: 100%;
  max-width: 300px;
  background-color: rgba(255,255,255,0.4);
}

.apply .filter-wrapper {
  margin-top: 12px;
  column-gap: 8px;
}

.apply .jobsPreview{
  margin-top: 20px;
}

.apply .jobsPreview {

}

.apply .jobsPreview .jobsPreviewTxt {
  flex-wrap: wrap;
  column-gap: 100%;
}

.apply .jobsPreview .title p{
  font-weight: 600;
  font-size: 1.2em;
}

.apply .jobsPreview .location{
  padding: 4px 0 0 0;
}

.apply .jobsPreview .location p{
  font-size: 0.8em;
}

.apply .jobsPreview .description{
  align-items: center;
  column-gap: 2px;
  font-weight: 400;
  font-size: 0.8em;
  margin: 4px 0;
}

.apply .jobsPreview .description svg{
  width: 24px;
}

.apply .jobsPreview .workType{
}

.apply .jobsPreview .workType p{
  border: 1px solid grey;
  border-radius: 100px;
  padding: 4px 8px;
  font-size: 0.8em;
  opacity: 0.7;
}

.apply .jobsOverview{
  overflow: scroll;
  height: 100vh;
}
.hide{display: none;}
.show{display: block;}
@media (min-width: 768px){
  .hide{display: block;}
  .show{display: block;}
}

.resetfilterMobil{
  display: none;
}

@media (max-width: 768px) {

  .apply .jobsOverview{
    height: unset;
  }

  .apply .filter{
    padding: 6px 8px
  }

  .resetfilterDesktop{
    display: none;
  }

  .resetfilterMobil{
    display: block;
  }
}


</style>