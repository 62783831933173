<template>
  <main class="content-1400 page-content">
    <div class="grid-12">
      <div class="col-sp-12 col-tb-8 row-sp-12 pad-8">
        <h1>Stellenanzeigen</h1>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-8 t-right pad-top-16">
        <router-link to="jobs/create" class="button primary">
          Neue erstellen
        </router-link>
      </div>
    </div>
    <div class="pad-8" />
    <div class="grid-12">
      <job-ad v-for="jobAd in current" :key="jobAd.id" :job-ad="jobAd" @delete-job-ad="deleteJobAd" />
    </div>
    <div class="pad-8" />
    <div class="pad-8">
      <h2>
        Deaktivierte Anzeigen
      </h2>
    </div>
    <div class="grid-12">
      <job-ad v-for="jobAd in deleted" :key="jobAd.id" :job-ad="jobAd" @delete-job-ad="deleteJobAd" />
    </div>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import JobAd from "@/components/jobs/jobad";
import {aSyncData} from "@/modules/main";


export default {
  name: "overview",
  title: "Jobs Overview",
  components: {
    JobAd,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      jobAds: [],
    }
  },
  computed: {
    current(){
      return this.jobAds.filter(j => !j.deleted)
    },
    deleted(){
      return this.jobAds.filter(j => j.deleted)
    }
  },
  mounted() {
    this.config.loading = false
    this.getJobAds()
  },
  methods: {
    getJobAds(){
     aSyncData( 'jobs/getJobAds')
          .then(r => {
            console.log(r.data)
            if(r.status === 250)  this.jobAds = r.data
          })
          .catch(e => {
            console.log(e)
          })
    },
    deleteJobAd(jobId){
      aSyncData('jobs/changeStatus',{
        jobId: jobId,
        status: !(this.jobAds.find(j => j.id === jobId).deleted)
      })
          .then(r => {
            console.log(r)
            if(r.status === 250) this.jobAds.find(j => j.id === jobId).deleted = !(this.jobAds.find(j => j.id === jobId).deleted)
          })
          .catch(e => {})
    }
  }
}
</script>

<style scoped>

</style>