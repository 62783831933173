<template>
  <main class="content-1400 page-content">
    <div class="pad-8">
      <router-link to="/jobs" class="button">
        Zurück zur Übersicht
      </router-link>
    </div>
    <div class="grid-12">
      <div class="col-sp-12 row-sp-12 pad-8">
        <h1>Stellenanzeige erstellen</h1>
      </div>
    </div>
    <div class="pad-8" />
    <div class="grid-12">
      <div class="col-sp-12 row-sp-12">
        <section class="grid-12">
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="field">
              <label>Bildergalerie*</label>
              <div style="max-width: 50%">
                <cropper-upload :init="false" :ratio="8/3" @cropped="c => this.avatarBlob = c" />
              </div>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="field">
              <label>Stellenanzeigen Titel*</label>
              <select v-model="title">
                <option value="Aushilfskraft im Service">
                  Aushilfskraft im Service
                </option>
                <option value="Einsatzplanung">
                  Einsatzplanung
                </option>
                <option value="Familienbegleiter">
                  Familienbegleiter
                </option>
                <option value="Gesundheits- und Pflegeassistent*in">
                  Gesundheits- und Pflegeassistent*in
                </option>
                <option value="Hauswirtschafts- und Betreuungskraft">
                  Hauswirtschafts- und Betreuungskraft
                </option>
                <option value="Koch / Köchin">
                  Koch / Köchin
                </option>
                <option value="Küchenkraft">
                  Küchenkraft
                </option>
                <option value="Musiktherapeut*in">
                  Musiktherapeut*in
                </option>
                <option value="Palliative Care Fachkraft">
                  Palliative Care Fachkraft
                </option>
                <option value="Pflegedienstleitung">
                  Pflegedienstleitung
                </option>
                <option value="Pflegehilfskraft">
                  Pflegehilfskraft
                </option>
                <option value="Reinigungskraft">
                  Reinigungskraft
                </option>
                <option value="Servicekraft">
                  Servicekraft
                </option>
                <option value="Spülkraft">
                  Spülkraft
                </option>
                <option value="Tagesmutter/Tagesvater">
                  Tagesmutter/Tagesvater
                </option>
                <option value="Alltagsbegleitung">
                  Alltagsbegleitung
                </option>
                <option value="Bilanzbuchhalter*in">
                  Bilanzbuchhalter*in
                </option>
                <option value="Einrichtungsleitung">
                  Einrichtungsleitung
                </option>
                <option value="Fahrer*in">
                  Fahrer*in
                </option>
                <option value="Finanzbuchhalter*in">
                  Finanzbuchhalter*in
                </option>
                <option value="Hausmeister*in">
                  Hausmeister*in
                </option>
                <option value="Hauswirtschaftskraft">
                  Hauswirtschaftskraft
                </option>
                <option value="Kinderkrankenpfleger*in">
                  Kinderkrankenpfleger*in
                </option>
                <option value="Küchenhilfskraft">
                  Küchenhilfskraft
                </option>
                <option value="Medizinische Fachangestellte">
                  Medizinische Fachangestellte
                </option>
                <option value="Nachtwache">
                  Nachtwache
                </option>
                <option value="Pflegeberater*in">
                  Pflegeberater*in
                </option>
                <option value="Pflegefachkraft">
                  Pflegefachkraft
                </option>
                <option value="Praxisanleitung">
                  Praxisanleitung
                </option>
                <option value="Sachbearbeiter*in">
                  Sachbearbeiter*in
                </option>
                <option value="Sozialpädagogische Assistenz">
                  Sozialpädagogische Assistenz
                </option>
                <option value="Stellvertretende Pflegedienstleitung">
                  Stellvertretende Pflegedienstleitung
                </option>
                <option value="Verwaltungskraft">
                  Verwaltungskraft
                </option>
                <option value="Wohnbereichsleitung">
                  Wohnbereichsleitung
                </option>
              </select>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-8">
            <div class="field">
              <label>Standort*</label>
              <select v-model="activeLocation">
                <option value="-1" disabled>
                  Standort...
                </option>
                <option v-for="location in locations" :key="location.id" :value="location.id">
                  {{ location.city }} - {{ location.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-8">
            <div class="field">
              <label>Beschäftigungsart*</label>
              <select name="worktype" v-model="worktype">
                <option value="-1" disabled>
                  Beschäftigungsart...
                </option>
                <option value="Vollzeit">
                  Vollzeit
                </option>
                <option value="Teilzeit">
                  Teilzeit
                </option>
                <option value="Ausbildung">
                  Ausbildung
                </option>
                <option value="Duales Studium">
                  Duales Studium
                </option>
                <option value="Werkstudent*in">
                  Werkstudent*in
                </option>
                <option value="FSJ">
                  FSJ
                </option>
                <option value="Praktikum">
                  Praktikum
                </option>
                <option value="Minijob">
                  Minijob
                </option>
              </select>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-8">
            <div class="field">
              <label>Gesucht zum*</label>
              <input type="date" name="startdate" v-model="startdate">
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="field">
              <label>Stellenbeschreibung*</label>
              <textarea placeholder="Stellenbeschreibung" rows="4" name="description" v-model="description" />
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="field">
              <label>Benefits* <span style="font-weight: normal; font-size: 14px;">(bitte 3 Benfits auswählen)</span></label>
              <select multiple v-model="activeBenefits">
                <option v-for="benefit in benefits" :key="benefit.id" :value="benefit.id">
                  {{ benefit.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="field">
              <label>Unser Angebot*</label>
              <textarea placeholder="Unser Angebot" rows="4" name="offer" v-model="offer" />
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="field">
              <label>Das wünschen wir uns von dir*</label>
              <textarea placeholder="Stellenbeschreibung" rows="4" name="profile" v-model="profile" />
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8 t-right">
            <button @click="createJobAd" class="button primary">
              Veröffentlichen
            </button>
          </div>
        </section>
      </div>
    </div>

    <div class="pad-8" />
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import CropperUpload from "@/components/images/cropperUpload";
import {aSyncData} from "@/modules/main";

export default {
  name: "overview",
  title: "Jobs Create",
  components: {
    CropperUpload
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      activeBenefits: [],
      activeLocation: '',
      benefits: [],
      locations: [],
      offer: '',
      description: '',
      profile: '',
      startdate: '',
      worktype: '',
      title: '',
      avatarBlob: null
    }
  },
  mounted() {
    this.config.loading = false
    this.getBenefits()
    this.getLocations()
  },
  methods: {
    getBenefits(){
      aSyncData('jobs/getBenefits')
        .then(r => {
          if(r.status === 250) {
            this.benefits = r.data
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    getLocations(){
      aSyncData('jobs/getLocations')
          .then(r => {
            if(r.status === 250) {
              this.locations = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    createJobAd(){
      const fD = {
        activeBenefits: this.activeBenefits,
        activeLocation: this.activeLocation,
        offer: this.offer,
        description: this.description,
        profile: this.profile,
        startdate: this.startdate,
        worktype: this.worktype,
        title: this.title,
        avatar: this.avatarBlob,
      }
      aSyncData('jobs/createJobAd', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(r=> {
            console.log(r)
            if(r.status === 250){
              this.$router.push('/jobs')
            }
          })
          .catch(e => {console.error(e)})
    },
  }
}
</script>

<style scoped>
.field label{
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
}
.field input,
.field textarea,
.field select{
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--theme-pflegediakonie-secondary);
  border-radius: 4px;
  border: none;
  padding: 12px;
  max-width: 100%;
}
.field input:focus,
.field textarea:focus,
.field select:focus{
  outline: 2px solid var(--theme-pflegediakonie-primary);
}
</style>